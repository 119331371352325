<template>
  <Container class="items-center">
    <ErrorBanner v-if="healthcheckStore.hasError" class="w-full md:w-1/3 mx-auto" />
    <component
      :is="'script'"
      src="https://www.google.com/recaptcha/api.js?render=6LdmLnojAAAAAHadYEvCC2LgrLHiE8Xsay6U_Pmo"
    />
    <div class="p-4 bg-white rounded-xl shadow-xl w-full md:w-1/2 lg:w-2/6 md:mt-20 mt-4">
      <div class="flex justify-center items-center">
        <img class="h-12" src="~/assets/svgs/logo.svg" alt="Logo" />
      </div>
      <hr class="mt-5 mb-2" />
      <form method="post" @submit="submitData">
        <div class="mt-5">
          <label>Elektronski naslov</label>
          <Input id="email" v-model="model.email" type="email" required autofocus />
        </div>
        <div class="mt-2">
          <label>Geslo</label>
          <Input
            id="password"
            v-model="model.password"
            type="password"
            class="rounded w-full"
            required
          />
        </div>
        <div class="my-5">
          <p>
            Si pozabil/a svoje geslo?
            <br /><a
              :href="buildPasswordResetLink"
              class="text-green-main hover:text-primary-200 hover:underline cursor:pointer"
              >Ponastavi ga tukaj</a
            >
          </p>
        </div>
        <Button id="loginButton" class="w-full" type="submit" data-testid="loginButton"
          >Vpiši se</Button
        >
      </form>

      <ul class="mt-4 text-red-600 list-disc ml-5">
        <li v-for="(err, idx) in loginErrors" :key="idx">
          {{ err }}
        </li>
      </ul>
      <GoogleRecaptchaTerms />
    </div>
    <div class="mt-4 text-center">
      <br />
      <p>Še nimaš poslovnega računa?</p>
      <Anchor :href="Routes.Register"> Kreiraj nov poslovni račun </Anchor>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import Container from '~/components/common/Container.vue'
import Button from '~/components/common/Button.vue'
import Input from '~/components/common/Input.vue'
import { useUserStore } from '~/stores/user.store'
import { AuthApi, Configuration } from '~/client_api'
import { axiosInstance } from '~/client_api/exios'
import { saveAccessToken } from '~/services/authentication'
import { Routes } from '~/routing/routes'
import GoogleRecaptchaTerms from '~/components/GoogleRecaptchaTerms.vue'
import Anchor from '~/components/common/Anchor.vue'
import { useHealthcheckStore } from '~/stores/healthcheck'
import ErrorBanner from '~/components/features/ErrorBanner.vue'

definePageMeta({
  layout: 'public',
  // middleware: ['auth'],
})

const userStore = useUserStore()
const healthcheckStore = useHealthcheckStore()

const model = reactive({
  email: '',
  password: '',
  recaptchaToken: '',
})
const loginErrors: string[] = reactive([])

const submitData = (e: any) => {
  e.preventDefault()
  grecaptcha.ready(function () {
    grecaptcha
      .execute('6LdmLnojAAAAAHadYEvCC2LgrLHiE8Xsay6U_Pmo', { action: 'submit' })
      .then(function (token: string) {
        loginErrors.length = 0
        model.recaptchaToken = token
        new AuthApi(new Configuration(), '', axiosInstance)
          .authDjLoginCreate(
            {
              email: model.email,
              password: model.password,
              recaptcha_token: model.recaptchaToken,
            },
            { withCredentials: true }
          )
          .then((response) => {
            if (response.status === 200) {
              saveAccessToken(response.data.key)
            }
            userStore.isLoggedIn = true
            window.location.href = Routes.Dashboard
          })
          .catch((err) => {
            if (err.response.status === 400 && err.response.data?.non_field_errors) {
              if (
                err.response.data.non_field_errors.length === 1 &&
                err.response.data.non_field_errors[0] === 'E-mail is not verified.'
              ) {
                loginErrors.push('Elektronski naslov še ni bil verificiran')
              } else {
                loginErrors.push(...err.response.data.non_field_errors)
              }
            } else if (err.response.status === 429) {
              loginErrors.push('Preveč napačnih poizkusov vpisa. Poskusite čez minuto.')
            }
          })
      })
  })
}

const buildPasswordResetLink = computed((): string => {
  let url = Routes.PasswordResetRequest
  if (model.email.length) {
    url = `${url}?email=${model.email}`
  }
  return url
})

const router = useRouter()

onMounted(() => {
  // if (userStore.isLoggedIn) {
  //   router.replace(Routes.Dashboard)
  //   return
  // }
  const route = useRoute()
  if (route.query.session_expired === '1') {
    const toaster = useToast()
    toaster.error('Vaša seja je potekla, zato ste bili izpisani iz sistema')
  }
})
</script>
